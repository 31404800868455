.App {
  text-align: center;
}

.App-logo {
  height: 4vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
  animation: App-logo-spin infinite 2s linear;
  }
} */

.Details {
  max-width: 30vw;
  color: rgb(40, 38, 38);
  text-align: justify;
  font-size: smaller;
}
.App-header {
  background-color: #dcdcdc;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #070707;
  font-size: small;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1,1)
  }
  to {
    transform: scale(1.2,1.2);
  }
  from {
    transform: scale(1.2,1.2)
  }
  to {
    transform: scale(1,1);
  }
}
